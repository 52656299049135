import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorKamil as author } from 'data/authors';
import { Post } from 'components/blog/content/styled';
import img from 'img/blog/intercomxls.png';
import img1 from 'img/blog/intercomimg.png';

const Content = () => {
  return (
    <Post>
      <p>Great news for Intercom users. We’ve just released a new integration!</p>
      <p>
        The integration adds a <strong>LiveSession recording link</strong> to every{' '}
        <strong>Intercom conversation</strong> with an identified user:
      </p>
      <img src={img1} alt="intercom integration - chat" title="Intercom conversation" />
      <p>
        Now you can open recordings of a specific user directly from Intercom. You don’t have to
        search for the right session manually anymore. This improvement will be particularly helpful
        for your tech support agents.
      </p>
      <p>
        What’s more, the integration allows you to add recording URLs as an attribute or a user’s
        event in Intercom. If you’re interested in details, you can read more in our{' '}
        <a href="/help/intercom-integration/" target="_blank" rel="noopener noreferrer">
          help center
        </a>
        .
      </p>
      <p>Sounds like just what you needed?</p>
      <p>
        <a
          href="/integrations/intercom/"
          target="_blank"
          rel="noopener noreferrer"
          className="action-btn"
        >
          Install now
        </a>
      </p>
      <p>
        As always, we’re happy to help if you have any questions. Please get in touch via live chat
        in the bottom right corner or send us an email to{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Intercom integration',
  url: '/blog/intercom-integration/',
  description: `We've released a new integration. Now you can connect LiveSession with
  Intercom`,
  author,
  img,
  imgSocial: img,
  date: '2020-04-17',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
